import { useEffect } from 'react';

const OneTrustScript = () => {
  useEffect(() => {
    // Define the external OneTrust script URL and domain ID
    const otSDKStubUrl = process.env.OTSDKSTUB_URL || 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    const dataDomainIdOtSDKStub = process.env.DATA_DOMAIN_ID_OTSDKSTUB || '0b73d170-24a4-4df4-b051-b4a2a49b8d6f';

    // Create the external OneTrust script element
    const scriptElement = document.createElement('script');
    scriptElement.src = otSDKStubUrl;
    scriptElement.type = 'text/javascript';
    scriptElement.setAttribute('data-document-language', 'true');
    scriptElement.setAttribute('data-domain-script', dataDomainIdOtSDKStub);

    // Append the external script to the document head
    document.head.appendChild(scriptElement);

    // Create the inline script for OptanonWrapper
    const inlineScript = document.createElement('script');
    inlineScript.type = 'text/javascript';
    inlineScript.innerHTML = `
      function OptanonWrapper() {
        console.log('Inside OneTrust popup');
      }
    `;

    // Append the inline script to the document head
    document.head.appendChild(inlineScript);

    // Cleanup both scripts on component unmount
    return () => {
      document.head.removeChild(scriptElement);
      document.head.removeChild(inlineScript);
    };
  }, []);

  return null; // No need to render anything
};

export default OneTrustScript;
